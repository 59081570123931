<template>
    <div class="Coupon">
          <div class="item-header flex">
            <div class="screen-list-item flex">
                <div class="flex" style="flex:1">
                    <span class="screen-item-label">分类类型 </span> <!--{{$i18n.message.setting}} -->
                     <a-select v-model="classify_type" style="flex:1;height:36px;" placeholder="请选择">
                        <a-select-option v-for="(item,j) in classify_type_arr" :key="j" :value="item.Id">
                           {{item.Name}}
                        </a-select-option>
                     </a-select>
                </div>
            </div>
            <div class="screen-list-item flex">
                <span class="screen-item-label">优惠名称</span>
                <a-input placeholder="请输入优惠名称" style="flex: 1;height: 36px;" v-model="classify_name"/>
            </div>
            <div class="screen-list-item flex" style="flex: 1.3">
                <span class="screen-item-label">创建时间</span>
                <a-range-picker style="flex: 1" format="YYYY-MM-DD" :placeholder="['开始时间','结束时间']" allowClear @change="changeTime"/>
            </div>
            <div class="screen-list-item">
                <a-button style="margin-right:20px" @click="resetBtn()">重置</a-button>
                <a-button type="primary" @click="serachBtn()">查询</a-button>
            </div>
        </div>

        <div class="coupon-content" :style="{'min-height':minHeight}">
            <div class="content-top flex">
                <div class="content-title">
                    <!--<span>考试问卷</span> <a-icon type="appstore" /> <a-icon type="bars" /> -->
                </div>
                <div>
                    <a-button type="primary" @click="onTtableClick('add','add')" ><a-icon type="plus" />新建优惠</a-button>
                </div>
            </div>

            <div style="margin-top:15px;">
                <a-table :columns="columns" :dataSource="infoData" :pagination="false" bordered :rowKey="record => record.Title" v-if="infoData">
                    <template slot="Type" slot-scope="Type">
                        <span v-if="Type==1">代金券</span>
                        <span v-if="Type==2">课时劵</span>
                    </template> 
                    <template v-if="Price" slot="Price" slot-scope="Price">
                       {{Price}}
                    </template> 
                    <template slot="IsReceive" slot-scope="IsReceive">
                       {{IsReceive==1?'可领取':'不可领取'}}
                    </template>
                     <template slot="numStatus" slot-scope="numStatus">
                       {{numStatus}}
                    </template>
                      <template slot="shoolName" slot-scope="shoolName">
                       {{shoolName?shoolName.join(','):''}}
                    </template>
                     <template slot="ApplianceType" slot-scope="ApplianceType">
                       {{ApplianceType==1?'课程':'不限'}}
                    </template>
                    <template slot="MemberType" slot-scope="MemberType">
                        <span v-if="MemberType==1">会员</span>
                        <span v-else-if="MemberType==2">非会员</span>
                        <span v-else>不限</span>
                    </template>
                    <div slot="action" slot-scope="record">
                        <a-icon type="eye" class="icon-btn ant-menu-item-selected" style='cursor:pointer;margin-right:8px;' @click="onTtableClick(record,'look')"/>
                        <a-icon type="form" class="icon-btn ant-menu-item-selected" style='cursor:pointer;margin-right:8px;' @click="onTtableClick(record,'details')"/>
                        <a-popconfirm placement="top" cancelText="取消" okText="确定"  @confirm="() => onTtableClick(record,'delete')">
                            <template slot="title">
                                <p>确定删除么？</p>
                            </template>
                            <a-icon type="delete" style='cursor:pointer;' class="icon-btn ant-menu-item-selected"/>
                        </a-popconfirm>
                    </div>

                </a-table> 
            </div>
            <div style="margin: 20px 0;float:right">
                <a-pagination showQuickJumper :defaultCurrent="1" :total="total" :pageSize="page" @change="pageChange" />
            </div>
            <!--点击table <a-table
                :columns="columns"
                :dataSource="infoData"
                :pagination="false"
                :rowKey="record => record.sNumber"
                bordered
                :customRow="rowClick"
                v-if="infoData"
            >
            </a-table> -->
        </div>
        
        <a-drawer
        :title="changeTitle"
        :width="520"
        @close="onClose_drawer"      
        :visible="visible"
        :closable = false
        :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
        >
            <a-form class="coupon_form">
                    <a-form-item  v-bind="formItemLayout" label="名称">
                      <a-input v-model="name" placeholder="请输入名称" />
                    </a-form-item>
                   <a-form-item v-bind="formItemLayout" label="分类">
                    <a-select
                       v-model="categoryId"
                       placeholder="请选择"
                    >
                    <a-select-option v-for="(item,j) in classify_type_arr" :key="j" :value="item.Id">{{item.Name}}</a-select-option>
                    </a-select>
                    </a-form-item> 
                   <a-form-item v-bind="formItemLayout" label="适用范围"> 
                      <!-- <sSchool v-bind="centerObj":style="{width: width}" @getCenterData="getCenterData" style="width:100%;"></sSchool> -->
                        <a-tree-select
                            :treeData="treeDataSchool"
                            :value="SchoolVal"
                            @change="onChangeSchool"
                            treeCheckable
                        :showCheckedStrategy="SHOW_PARENT"
                            searchPlaceholder="请选择中心"
                        />  
                   </a-form-item>
                   <a-form-item v-bind="formItemLayout" label="适用产品">
                    <a-radio-group v-model="applianceType">
                        <a-radio value="1">
                        课程
                        </a-radio>
                        <a-radio value="0">
                        不限
                        </a-radio>
                    </a-radio-group>
                    </a-form-item> 
                   <a-form-item v-bind="formItemLayout" label="适用时间">
                   <a-range-picker v-if="startTime" style="flex: 1" format="YYYY-MM-DD" 
                   :defaultValue="[moment(startTime, dateFormat), moment(endTime, dateFormat)]"
                   :placeholder="['开始时间','结束时间']"
                   allowClear 
                   @change="createChangeTime"
                   />
                   <a-range-picker v-else style="flex: 1" format="YYYY-MM-DD" 
                   :placeholder="['开始时间','结束时间']" 
                   allowClear 
                   @change="createChangeTime"
                   />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="优惠券类型" >
                        <a-radio-group v-model="type">
                            <a-radio v-for="(item,j) in options" :key='j' :value="item.label">{{item.value}}</a-radio>
                        </a-radio-group> 
                    </a-form-item> 
                    <a-form-item  v-if="type=='1'" v-bind="formItemLayout" label="面值">
                    <a-input-number
                        :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        v-model="price"
                        :step='1'
                        :precision = 0
                    />
                    </a-form-item>
                    <a-form-item v-else v-bind="formItemLayout" label="课次">
                    <a-input-number
                        :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :step='1'
                        :min =0
                        :precision = 0
                         v-model="price"
                         placeholder="请输入课次"
                    />
                    </a-form-item>
                   <a-form-item v-bind="formItemLayout" label="数量">
                    <a-input-number
                        :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        v-model="quantity"
                        :step='1'
                        :precision = 0
                        :min=1
                        placeholder ="请输入数量"
                    />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="成本">
                    <a-input-number
                        :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        v-model="cost"
                        :step='0.01'
                        :min =0
                        :precision = 2
                    />
                    </a-form-item>
                    <a-form-item  v-bind="formItemLayout" label="劵号前缀">
                    <a-input v-model="prefix" placeholder="请输入字母" @keyup="controlFn($event)" @blur="controlFn($event)" />
                    </a-form-item>
               
                    <a-form-item  v-bind="formItemLayout" style="display:flex;" label="密码类型">
                    <a-radio-group v-model="passwordType">
                        <a-radio v-for="(item,j) in optionsPoss" :key='j' :value="item.label">{{item.value}}</a-radio>
                        <a-input-number
                            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            v-model="passwordLength"
                            :step='1'
                            :precision = 0
                            :min = 4
                            :max = 16
                            placeholder="请设置密码长度"
                        />    
                    </a-radio-group>     
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="适用会员类型">
                    <a-radio-group v-model="memberType">
                         <a-radio v-for="(item,j) in optionsMember" :key='j' :value="item.label">{{item.value}}</a-radio>
                    </a-radio-group>
                    </a-form-item> 
                    <a-form-item v-bind="formItemLayout" label="是否可领取">
                    <a-switch checkedChildren="是" unCheckedChildren="否" v-model="isReceive" />
                    </a-form-item>
                     <a-form-item v-bind="formItemLayout" label="生产方式">
                     <a-radio-group v-model="generateType">
                        <a-radio value="1">
                         即时生成
                        </a-radio>
                        <a-radio value="2">
                         按需生成<span style="font-size:8px;color:#e10000;"> (领取后生成在券表)</span>
                        </a-radio>
                    </a-radio-group>
                    </a-form-item>
            </a-form>  
            <div
                v-if="((detailsObj.CreateUserId==user.uid)||(changeTitle=='新建优惠方案'))"
                :style="{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                }"
            >
                <a-button :style="{marginRight: '8px',width:'96px'}" @click="onClose_drawer">取消</a-button>
                <a-button :style="{width:'96px',}" @click="subBtn()" :disabled="secoedBool" type="primary"><span v-if="secoedBool">( {{secoed }} ) </span>{{changeTitle=='新建优惠方案'?'新建':'修改'}}</a-button>
            </div> 
        </a-drawer>
        
        <a-drawer
        :title="changeTitle"
        :width="730"
        @close="onClose_drawer"
        :closable = true
        :visible="showModel"
        :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
        >
             <a-row :gutter="16" style="margin-bottom: 26px;text-align:center">
                <a-col :span="8">
                    <a-card hoverable :title="'已使用 '+detailInfo.used">
                    </a-card>
                </a-col>
                <a-col :span="8">
                    <a-card hoverable :title="'未使用'+detailInfo.unused" >
                    </a-card>
                </a-col>
                <a-col  :span="8">
                    <a-card hoverable :title="'已领取'+detailInfo.received" >
                    </a-card>
                </a-col>
            </a-row>
        <!-- <a-modal title="已使用" v-model="showModel" :footer="null" :centered='true' width='730px' :bodyStyle="{width:'730px','max-height':'500px'}"> -->
            <a-table :columns="columns2" :dataSource="data2" :pagination='false' :rowKey="record => record.name" bordered size='small'>
            </a-table>
            <a-pagination style="margin-top:10px;" @change="onChangeTable" :pageSize='page2' :current="current" size="small" :total="detailInfo.total?detailInfo.total:2"/>
        <!-- </a-modal> -->
         </a-drawer>
        <div
            v-show="spinning"
            style="position:fixed;left:50%;"
            >
            <a-spin style="margin: 150px 0%;">
                <a-icon
                slot="indicator"
                type="loading"
                :spinning="spinning"
                style="font-size: 44px;color:red;"
                spin
                />
            </a-spin>
        </div>
    </div>
</template>
<script>

// import sSchool from '../../components/SelectSchool/SelectSchool';//选中心
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
import moment from 'moment';
const columns2 = [
    {
      title: '劵名（券码）',
      dataIndex: 'PayVoucher',
      key: 'PayVoucher',
    },
    {
      title: '领取时间',
      dataIndex: 'ValidateTime',
      key: 'ValidateTime',
    },
    {
      title: '使用时间',
      dataIndex: 'UseTime',
      key: 'UseTime',
    },
    {
      title: '使用人',
      dataIndex: 'OwnerName',
      key: 'OwnerName',
    },
    {
      title: '使用中心',
      dataIndex: 'OrgName',
      school: 'OrgName',
    },
  ];
//   [
//     {
//       name: '双十一48课包优惠2000元3',
//       getTime: '2018-10-26',
//       setTime: '2018-11-01',
//       person:'周美惠子',
//       school:'东海东海东海东海'
//     },
//   ];
export default {
    name:'Coupon',
    // components:{sSchool},
    data(){
        return{
            treeDataSchool: [],
            SHOW_PARENT,
            spinning: false,
            dateFormat: 'YYYY-MM-DD',
            //  centerObj:{centerType: 'center12',width: '100%',value:[]},//选中心的参数
             formItemLayout: {
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
             },
            page: 5,
            index: 1,
            total: 1,//总数
             //搜索
            classify_type_arr: [],
            classify_type: '', 
            classify_name: '',
            startDate: '',
            endDate: '',
            //新建的
            name:'',
            categoryId:'',//分类
            SchoolVal:'',
            applianceType: '1',//适用产品 0为不限 ，1为课程
            startTime: '',
            endTime: '',
            type: '2',//类型 1代金券/ 2课时劵（默认）
            price: '',//类型（1代金券/ 2课时劵）的面值
            quantity: '',//数量
            cost: '',//成本
            memberType: '1',//会员类型 0不限，1会员，2非会员
            prefix:'',//券号前缀
            passwordType: '3',//密码类型默认值1英文，2数字，3混合
            passwordLength: '',//密码长度
            generateType: '1',//生成方式：1即时生成，2按需生
            isReceive: false,// 是否可领取：0不可领取，1可领取

            options:[
                { value: '课时劵', label: '2' },
                { value: '代金券', label: '1' },
            ],
             optionsMember:[
                { label: '1', value: '会员' },
                { label: '2', value: '非会员' },
                { label: '0', value: '不限' },
            ],
            optionsPoss:[
                { label: '1', value: '字母' },
                { label: '2', value: '数字' },
                { label: '3', value: '混合' },
            ],
            columns2, 
            data2: [],
            detailInfo: 1,
            current:1,
            page2: 20,
            columns: [
                { title: '名称', dataIndex: 'Name', key: 'Name',align:'center',width:300 },
                { title: '类型', dataIndex: 'Type', key: 'Type',align:'center',width:120,scopedSlots: { customRender: 'Type' }, },
                { title: '面值', dataIndex: 'Price', key: 'Price',align:'center',width:100 ,scopedSlots: { customRender: 'Price' },},
                { title: '数量', dataIndex: 'numStatus', key: 'numStatus',align:'center',width:130 ,scopedSlots: { customRender: 'numStatus' },},
                { title: '是否可领取', dataIndex: 'IsReceive', key: 'IsReceive',align:'center',width:180,scopedSlots: { customRender: 'IsReceive' },},
                { title: '适用范围', dataIndex: 'shoolName', key: 'shoolName',align:'center',width:580,scopedSlots: { customRender: 'shoolName' }, },
                { title: '适用产品', dataIndex: 'ApplianceType', key: 'ApplianceType',align:'center',width: 200, scopedSlots: { customRender: 'ApplianceType' }, },
                { title: '适用人群', dataIndex: 'MemberType', key: 'MemberType',align:'center',width: 120, scopedSlots: { customRender: 'MemberType' },},
                { title: '操作栏', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' },align:'center',width: 120 },
            ],
            infoData: [
                {   
                    // sNumber: 1,
                    Name: '新员工学习',
                    Type: '代金券/课时劵',// 1/2
                    FaceValue: '金额/课时',
                    NumType: '已用/已领/总数',
                    Status: '是',
                    ApplicationRange: '48课包',
                    ProductRange: '课包',
                    MemberType: '会员/非会员/不限'
                }
            ],
            visible: false,
            showModel: false,
            minHeight:'500px',
            changeTitle: '新建优惠方案',
            secoed: 5,//倒计时s
            secoedBool: false,
            detailsObj: {},//详情的（修改用）
            user: {},
        }
    },
    
    created(){
        this.$axios.get(9979,{},res=>{
          this.classify_type_arr = res.data.data; 
        })
        this.tableFn(this.index,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
        //    this.user= JSON.parse(window.sessionStorage.getItem('userInfo'));
    },
    mounted(){
         this.$axios.get(9980,{},res=>{this.treeDataSchool = res.data.data; })
         let clientH=document.documentElement.clientHeight; 
         this.minHeight = clientH-242+'px';
         this.user= JSON.parse(window.sessionStorage.getItem('userInfo'));
    },
    methods:{
     moment,
      //table
       tableFn(index,page,categoryId,name,startTime,endTime){
            let that = this; 
            that.spinning = true;
            this.$axios.get(9978,{index: index,page: page,categoryId:categoryId,name:name,startTime:startTime,endTime:endTime},res=>{ 
               that.spinning = true;
            //    console.log(res);
               if(res.data.code!=1){that.$message.error(res.data.msg);}
               that.infoData = [];
               that.infoData = res.data.data;
               that.total = res.data.total;
            })
      },
      //详情表格
      details_table(id,current){
          let that = this;
          that.spinning = true;
          that.$axios.post(9977,{id:id,index: current,page: that.page2},res=>{
            try{
                if(res.data.code==1){
                    that.detailInfo = [];
                    that.data2 = res.data.data;
                    that.detailInfo = res.data;
                    that.spinning = false;
                }else{
                    that.$message.error(res.data.msg);
                }
            }catch(error){
              console.log(error);
              console.log('ajax预请求!')
            }
         
          })
      },
      onTtableClick(delObj,type){
        //   console.log(delObj)
          this.detailsObj = delObj;
          let that = this;
          if(type=='add'){
            this.changeTitle="新建优惠方案";
            this.name = '';
            this.categoryId = '';//分类
            this.SchoolVal = '';
            this.applianceType = '1';//适用产品 0为不限 ，1为课程
            this.startTime = '';
            this.endTime = '';
            this.type = '2';//类型 1代金券/ 2课时劵（默认）
            this.price = '';//类型（1代金券/ 2课时劵）的面值
            this.quantity = '';//数量
            this.cost = '';//成本
            this.memberType = '1';//会员类型 0不限，1会员，2非会员
            this.prefix = '';//券号前缀
            this.passwordType = '3';//密码类型默认值1英文，2数字，3混合
            this.passwordLength = '';//密码长度
            this.generateType = '1';//生成方式：1即时生成，2按需生
            this.isReceive = false;// 是否可领取：0不可领取，1可领取
            this.visible = true;
          }
          if(type=='look'){
              this.showModel =true;
              this.details_table(delObj.Id,1);
              this.changeTitle = delObj.Name+'优惠券';
          }
          if(type=='delete'){
            //   console.log('删除');
            that.spinning = true;
            that.$axios.post(9975,{id:delObj.Id,uid:that.user.uid},res=>{
                if(res.data.code==1){
                that.spinning =false;
                that.$message.success(res.data.msg);
                that.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
                }else{
                    that.$message.error(res.data.msg);
                }
            })
          }if(type=='details'){
            // console.log('修改');
            this.changeTitle = this.detailsObj.Name;
            this.visible = true;

            this.name = this.detailsObj.Name;
            this.categoryId = this.detailsObj.CategoryId; //分类'
            if(this.detailsObj.SchoolId.length>0){
                this.SchoolVal = this.detailsObj.SchoolId.split(','); //中心'
            }
            this.applianceType = String(this.detailsObj.ApplianceType); //适用产品 0为不限 ，1为课程'
            this.startTime = this.detailsObj.StartTime;
            this.endTime = this.detailsObj.EndTime;
            this.type = String(this.detailsObj.Type); //类型 1代金券/ 2课时劵（默认）'
            this.price = String(this.detailsObj.Price); //类型（1代金券/ 2课时劵）的面值'
            this.quantity = this.detailsObj.Quantity; //数量'
            this.cost = this.detailsObj.Cost; //成本'
            this.memberType = String(this.detailsObj.MemberType); //会员类型 0不限，1会员，2非会员'
            this.prefix = this.detailsObj.Prefix; //券号前缀
            this.passwordType = String(this.detailsObj.PasswordType);  //密码类型默认值1英文，2数字，3混合
            this.passwordLength = this.detailsObj.PasswordLength; //密码长度
            this.generateType = String(this.detailsObj.GenerateType);  //生成方式：1即时生成，2按需生 
            this.isReceive = this.detailsObj.IsReceive==1?1:0; // 是否可领取：0不可领取，1可领取

        }
        //   console.log(delObj,type)
      },
      //搜索时间
      changeTime(value) {
        this.startDate = value[0]._d;
        this.endDate = value[1]._d;
        let date1 = new Date(this.startDate);
        this.startDate = date1.getFullYear()+'-'+(date1.getMonth()+1<10?'0'+(date1.getMonth()+1):date1.getMonth()+1)+'-'+(date1.getDate()<10?'0'+date1.getDate():date1.getDate());
        let date2 = new Date(this.endDate);
        this.endDate = date2.getFullYear()+'-'+(date2.getMonth()+1<10?'0'+(date2.getMonth()+1):date2.getMonth()+1)+'-'+(date2.getDate()<10?'0'+date2.getDate():date2.getDate());
        // console.log(this.startDate,this.endDate)  
      },
      onClose_drawer() {
        this.visible = false;
        this.showModel = false;
      },
       onChangeSchool(value) {
            // console.log('onChange ', value);
            this.SchoolVal = value;
             console.log(this.SchoolVal)
       },
      //分页
      pageChange(pageNumber) {
        // console.log(pageNumber)
        this.index = pageNumber;
        this.tableFn(this.index,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
      },
     //model 分页
     onChangeTable(current){
         this.current = current;
        //  console.log(current)
         this.details_table(this.detailsObj.Id,current)
     },
    // 点击table
    //   rowClick: (record, index) => {
    //    return {
    //         on: {
    //            click: () => {
    //                 console.log(record,index)
    //            }
    //         }
    //     }
    //   },
    //重置
    resetBtn(){
         this.classify_type= '';
         this.classify_name= '';
         this.startDate = '';
         this.endDate = '';
         this.index = 1;
         this.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
    },
    //搜索按钮
    serachBtn(){
        if(!this.classify_type&&!this.classify_name&&!this.startDate&&!this.endDate){
           console.log('都为空不请求!');
          return false;    
        }
        this.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
    },
    openNotification(typeName,type) {//验证失败提示
        // console.log(typeName)
        let that = this;
        that.$notification.open({
            message: `${type==1?'请输入':'请选择'}${typeName}`,
            duration: 7,
            icon: <a-icon type="exclamation-circle" style="color: #E10000;" />,
        });
    },
    //表单
    // 提交
    subBtn(){
        let that = this;
        // console.log(
        //     this.name,'//名称',
        //     this.categoryId,'//分类',
        //     this.SchoolVal,'//中心',
        //     this.applianceType,'//适用产品 0为不限 ，1为课程',
        //     this.startTime,
        //     this.endTime,
        //     this.type,'//类型 1代金券/ 2课时劵（默认）',
        //     this.price,'//类型（1代金券/ 2课时劵）的面值',
        //     this.quantity,'//数量',
        //     this.cost,'//成本',
        //     this.memberType,'//会员类型 0不限，1会员，2非会员',
        //     this.prefix,'//券号前缀',
        //     this.passwordType,'//密码类型默认值1英文，2数字，3混合',
        //     this.passwordLength,'//密码长度',
        //     this.generateType,'//生成方式：1即时生成，2按需生',
        //     this.isReceive?1:0,'// 是否可领取：0不可领取，1可领取',
        // )

        if(!this.name){ this.openNotification('名称',1);return false;}
        if(!this.quantity){this.openNotification('数量',1); return false;}
        if(!this.cost){ this.openNotification('成本',1);return false;}
        if(!this.prefix){ this.openNotification('券号前缀',1);return false;}
        if(!this.passwordLength){ this.openNotification('密码长度',1);return false;}
        if(!this.categoryId){this.openNotification('分类',2); return false;}
        if(!this.SchoolVal){this.openNotification('适用范围',2); return false;}
        if(!this.startTime){this.openNotification('时间',2);return false; }
        if(!this.price){
            if(this.type==2){
                this.openNotification('课时',1);
            }else{
                this.openNotification('面值',1);
            }
            return false;
        }

        that.secoedBool =true;
        let shus = setInterval(function(){
            if(that.secoed==0){
                that.secoedBool = false;
                that.secoed = 5;
               clearInterval(shus);
            }else{
                that.secoed--;
            }
        },1000);
       if(that.secoedBool&&that.secoed<=4){return false;}

        that.spinning = true;
        let obj = {};
           obj.name = that.name;
           obj.categoryId = that.categoryId;
           obj.schoolId = that.SchoolVal.join(',');
           obj.applianceType = that.applianceType;
           obj.startTime = that.startTime;
           obj.endTime = that.endTime;
           obj.type = that.type;
           obj.price = that.price;
           obj.quantity = that.quantity;
           obj.cost = that.cost;
           obj.memberType = that.memberType;
           obj.prefix = that.prefix;
           obj.passwordType = that.passwordType;
           obj.passwordLength = that.passwordLength;
           obj.generateType = that.generateType;
           obj.isReceive = that.isReceive?1:0;

           obj.createUserId = that.user.uid;
           obj.createUserName = that.user.name;
           if(that.changeTitle!='新建优惠方案'){
               obj.id = that.detailsObj.Id;
           }
           console.log(obj)
        that.$axios.post(9976,obj,res=>{
            // console.log(res);
            if(res.data.code==1){
              that.spinning =false;
              that.$message.success(res.data.msg);
              that.visible = false;
              obj= {};
              that.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
            }else{
                that.$message.error(res.data.msg);
            }
        })
    },
    //分类
    // categoryChange(value){
    //     console.log(value);
    //     this.categoryId =value;
    // }, 
    //适用产品
    // applianceTypeFn(value){
    //     console.log(value,this.applianceType);
    // },
    //适用时间
      createChangeTime(value) {

        this.startTime = value[0]._d;
        this.endTime = value[1]._d;
        let date1 = new Date(this.startTime);
        this.startTime = date1.getFullYear()+'-'+(date1.getMonth()+1<10?'0'+(date1.getMonth()+1):date1.getMonth()+1)+'-'+(date1.getDate()<10?'0'+date1.getDate():date1.getDate());
        let date2 = new Date(this.endTime);
        this.endTime = date2.getFullYear()+'-'+(date2.getMonth()+1<10?'0'+(date2.getMonth()+1):date2.getMonth()+1)+'-'+(date2.getDate()<10?'0'+date2.getDate():date2.getDate());
        console.log(this.endTime , this.startTime)
      },
    // 券号前缀
    controlFn(value){
        // console.log(value.target.value)
        let val = value.target.value.replace(/[^a-zA-Z]/g,'').toUpperCase();
        this.prefix = val;
        // console.log(val)
    },
    //是否可领取
    //  switchChange(checked){
    //     console.log(checked);
    //  },
     //类型 （代金券/课时劵）
    //  price_type_fn(e){
    //      console.log('radio2 checked', e.target.value);
    //  },
     //生产密码类型
    //  optionsPossFn(e){
    //     console.log('radio2 checked', e.target.value);
    //  },
    //  getCenterData(val){//返回选中心的数据
    //      console.log(val);
    //      this.SchoolVal = val.join();
    //      console.log(this.SchoolVal)
    //  },

    },
}
</script>

<style lang="less" > 
    .Coupon{
         .item-header{
            padding: 15px 20px;
            background: #fff;
            border-radius: 4px;
            .screen-list-item{
                margin-right: 20px;
                flex: 1;
            }
            .screen-item-label{
                margin-right: 10px;
                color: #666;
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .coupon-content{
            padding: 15px;
            background: #fff;
            margin-top: 20px;
            border-radius: 4px;
            .content-title{
                span{
                    font-size: 18px;
                    color: #333;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
    }
    .Coupon .ant-select-selection--single,.Coupon .ant-input{height: 36px;}
    .Coupon .ant-select-selection__rendered{line-height: 34px;}
    .Coupon .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 6px;
    }
    .coupon_form{padding-bottom: 30px;}
    .coupon_form .ant-row .ant-form-item-label{text-align: left;}
    .coupon_form .ant-radio-group .ant-input-number{width: 124px;}
    .coupon_form .ant-input-number{width:330px;}
</style>